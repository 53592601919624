/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '@components/Button';
import buttonShape from '@features/WordPress/utilities/prop-types/shapes/button';
import {
  Root,
  Button as ButtonClass,
  LastButton,
  Inner,
  Shadow,
  GroupIsSplash,
  IsSplash,
  IsPrimary,
  HasBottomBorder,
} from './SlantedButtonGroup.module.css';

const SlantedButtonGroup = ({
  className,
  isSplash,
  buttonClass,
  buttons,
  hasBottomBorder,
}) => {
  const classes = classNames(
    Root,
    {
      [GroupIsSplash]: isSplash,
      [HasBottomBorder]: hasBottomBorder,
    },
    className
  );

  const firstButtonClasses = classNames(ButtonClass, buttonClass, Shadow);

  const lastButtonClasses = classNames(
    ButtonClass,
    buttonClass,
    {
      [IsSplash]: isSplash,
      [IsPrimary]: !isSplash,
    },
    LastButton
  );

  return (
    <div className={classes}>
      {buttons.map(
        (
          {
            text,
            innerClass,
            className: customButtonClass,
            hasArrow,
            appearance,
            ...button
          },
          index
        ) => {
          const innerClasses = classNames(Inner, innerClass);
          return (
            <Button
              key={index.toString()}
              className={[
                index === 0 ? firstButtonClasses : lastButtonClasses,
                customButtonClass,
              ]}
              innerWrapperClass={innerClasses}
              appearance={
                index === 0 ? appearance : isSplash ? 'splash' : 'primary'
              }
              hasArrow={hasArrow || false}
              {...button}
            >
              {text}
            </Button>
          );
        }
      )}
    </div>
  );
};

SlantedButtonGroup.defaultProps = {
  isSplash: false,
  buttonClass: '',
  hasBottomBorder: true,
};

SlantedButtonGroup.propTypes = {
  isSplash: PropTypes.bool,
  buttons: PropTypes.arrayOf(buttonShape).isRequired,
  buttonClass: PropTypes.string,
  hasBottomBorder: PropTypes.bool,
};

export default SlantedButtonGroup;
