import PropTypes from 'prop-types';
import classnames from 'classnames';
import { forwardRef, useContext } from 'react';
import {
  InputWrapper,
  ValidationError,
} from '@components/Input/Input.module.css';
import i18nStringsContext from '@contexts/i18nStringsContext';
import {
  Label,
  Checkbox as CheckboxClass,
  LabelText,
  Container,
  IsChecked,
  IsErrored,
  HiddenCheckbox,
} from './Checkbox.module.css';

const Checkbox = forwardRef(
  (
    {
      children,
      className,
      label,
      name,
      htmlId,
      error,
      readableName,
      checked,
      ...props
    },
    ref
  ) => {
    const styledCheckboxClasses = classnames(CheckboxClass, {
      [IsChecked]: props.value,
      [IsErrored]: error,
    });
    const inputWrapperClasses = classnames(InputWrapper, className);
    const { fieldIsRequired, fieldIsInvalid } = useContext(i18nStringsContext);

    const allProps = props;
    const labelText = label || children;
    const labelFor = allProps.id || name || htmlId;
    const friendlyName = readableName || name || labelText;

    if (labelText && !allProps.id) {
      allProps.id = name || htmlId;
    }

    const errorElement = error && (
      <span role="alert" className={ValidationError}>
        {error?.message ||
          (error?.type === 'required'
            ? fieldIsRequired?.replace('{fieldName}', friendlyName)
            : fieldIsInvalid?.replace('{fieldName}', friendlyName))}
      </span>
    );

    const input = (
      <>
        <div className={Container}>
          <input
            type="checkbox"
            className={HiddenCheckbox}
            ref={ref}
            aria-invalid={error ? 'true' : 'false'}
            checked={checked}
            {...allProps}
          />
          <div className={styledCheckboxClasses}>
            <svg viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </svg>
          </div>
        </div>
      </>
    );

    return labelText ? (
      <div className={inputWrapperClasses}>
        <label className={Label} htmlFor={labelFor}>
          {input}
          <div className={LabelText}>{labelText}</div>
        </label>
        {errorElement}
      </div>
    ) : (
      <div className={inputWrapperClasses}>
        {input}
        {errorElement}
      </div>
    );
  }
);

Checkbox.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Array),
  ]),
};

Checkbox.defaultProps = {
  className: '',
};

export default Checkbox;
