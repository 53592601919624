import PropTypes from 'prop-types';
import classnames from 'classnames';
import { forwardRef, useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@components/Tooltip';
import ConditionalWrapper from '@components/ConditionalWrapper';
import i18nStringsContext from '@contexts/i18nStringsContext';
import {
  Root,
  InputWrapper,
  Label,
  ValidationError,
  HasError,
  IsValid,
  ExtraSmall,
  Small,
  Medium,
  Large,
  ExtraLarge,
  Tooltip as TooltipClass,
  IsDisabled,
  AddonWrapper,
  PasswordEye,
  PasswordWrapper,
  IsVisible,
  FullWidth,
  UnderLabel,
} from './Input.module.css';

const SIZES = {
  'extra-small': ExtraSmall,
  small: Small,
  medium: Medium,
  large: Large,
  'extra-large': ExtraLarge,
};

const Input = forwardRef(
  (
    {
      children,
      className,
      label,
      name,
      htmlId,
      onChange,
      onBlur,
      error,
      readableName,
      inputWrapperClassName,
      showValidationErrorMessage,
      size,
      tooltip,
      addon,
      type,
      append,
      fullWidth,
      showValidIcon,
      underLabel,
      errorMessage,
      ...props
    },
    ref
  ) => {
    const [isValid, setIsValid] = useState();
    const [passwordShown, setPasswordShown] = useState(false);
    const classes = classnames(
      Root,
      {
        [SIZES[size]]: SIZES[size],
        [HasError]: error,
        [IsValid]:
          showValidIcon && typeof isValid !== 'undefined' && isValid && !error,
        [IsDisabled]: props.disabled,
        [FullWidth]: fullWidth,
      },
      className
    );
    const inputWrapperClasses = classnames(InputWrapper, inputWrapperClassName);
    const { fieldIsRequired, fieldIsInvalid } = useContext(i18nStringsContext);

    const [eyeIcon, setEyeIcon] = useState(true);

    const eye = (
      <FontAwesomeIcon
        icon={eyeIcon ? faEye : faEyeSlash}
        onClick={() => setEyeIcon(!eyeIcon)}
      />
    );

    const allProps = props;
    const labelFor = allProps.id || name || htmlId;
    const friendlyName = readableName || label || name;

    if (label && !allProps.id) {
      allProps.id = name || htmlId;
    }

    const errorElement = error && showValidationErrorMessage && (
      <div role="alert" className={ValidationError}>
        {error?.message ||
          (error?.type === 'required'
            ? fieldIsRequired?.replace('{fieldName}', friendlyName)
            : errorMessage ||
              fieldIsInvalid?.replace('{fieldName}', friendlyName))}
      </div>
    );

    const inputField = (
      <ConditionalWrapper
        condition={!!(addon || append)}
        wrapper={(child) => <div className={AddonWrapper}>{child}</div>}
      >
        {addon ? <span>{addon}</span> : null}
        <input
          onChange={onChange}
          onBlur={(e) => {
            if (onBlur) {
              onBlur(e);
            }
            setTimeout(
              setIsValid(e.target.getAttribute('aria-invalid') === 'false'),
              50
            );
          }}
          className={classes}
          name={name}
          {...allProps}
          type={passwordShown ? 'text' : type}
          ref={ref}
          aria-invalid={error ? 'true' : 'false'}
        />
        {typeof append === 'function' ? append({ isValid, error }) : append}
      </ConditionalWrapper>
    );

    const input = (
      <>
        {inputField}
        {type === 'password' && (
          <div className={PasswordWrapper}>
            <button
              type="button"
              onClick={() => setPasswordShown(!passwordShown)}
              // onKeyDown={() => setPasswordShown(!passwordShown)}
              className={classnames(PasswordEye, passwordShown && IsVisible)}
              // role="button"
              tabIndex={0}
            >
              {eye}
            </button>
          </div>
        )}

        {errorElement}
      </>
    );

    return label ? (
      <div className={inputWrapperClasses}>
        <label className={Label} htmlFor={labelFor}>
          {label}
          {tooltip ? (
            <Tooltip className={TooltipClass} content={tooltip} />
          ) : null}
        </label>
        {underLabel ? <div className={UnderLabel}>{underLabel}</div> : null}
        {input}
        {children}
      </div>
    ) : (
      <>
        {input}
        {children}
      </>
    );
  }
);

Input.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Array),
  ]),
  type: PropTypes.oneOf([
    'text',
    'password',
    'number',
    'email',
    'tel',
    'submit',
    'radio',
    'date',
    'zipcode',
  ]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf([
    'extra-small',
    'small',
    'medium',
    'large',
    'extra-large',
  ]),
  showValidationErrorMessage: PropTypes.bool,
  fullWidth: PropTypes.bool,
  addon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.node,
  ]),
  append: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.func,
    PropTypes.object,
  ]),
  showValidIcon: PropTypes.bool,
};

Input.defaultProps = {
  type: 'text',
  className: '',
  disabled: false,
  placeholder: '',
  size: 'medium',
  showValidationErrorMessage: true,
  addon: false,
  append: null,
  fullWidth: false,
  showValidIcon: false,
};

export default Input;
