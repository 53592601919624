/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import WPImage from '@features/WordPress/components/WPImage';
import CloseIcon from '@icons/cross.svg';
import {
  Root,
  Content,
  IsShowing,
  HasImage,
  Inner,
  ImageWrapper,
  Close,
  ImageCover,
  BottomModalClass,
  slideInFromBottom,
} from './Modal.module.css';

/* Modal */
const Modal = ({
  image,
  show,
  children,
  className,
  contentClass,
  displayImageAsCover,
  onClose,
  bottomModal,
  hideCloseButton,
  disableModalOutsideClick,
  maxWidth,
}) => {
  const hasImage = Object.keys(image).length > 0;

  const classes = classNames(
    Root,
    {
      [HasImage]: hasImage,
      [IsShowing]: show,
      [ImageCover]: displayImageAsCover,
    },
    className
  );

  const handleOutsideClick = () => {
    if (!disableModalOutsideClick) {
      onClose();
    }
  };

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.removeProperty('overflow');
    }
  }, [show]);

  const contentClasses = classNames(Content, contentClass);

  return show ? (
    <div
      className={classes}
      role="dialog"
      aria-modal="true"
      onClick={handleOutsideClick}
      style={{ ...(bottomModal && { padding: 0 }) }}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`${
          bottomModal
            ? `${BottomModalClass} ${slideInFromBottom}`
            : contentClasses
        }`}
        style={{
          ...(maxWidth && { maxWidth: maxWidth }),
        }}
      >
        {!hideCloseButton && (
          <div onClick={handleOutsideClick} className={Close}>
            <CloseIcon />
          </div>
        )}
        <div className={Inner}>{children}</div>
        {hasImage && (
          <div className={ImageWrapper}>
            <WPImage layout="fill" {...image} />
          </div>
        )}
      </div>
    </div>
  ) : null;
};

Modal.defaultProps = {
  image: {},
  show: false,
  onClose: () => {},
  contentClass: '',
  displayImageAsCover: false,
  disableModalOutsideClick: false,
};

Modal.propTypes = {
  image: PropTypes.instanceOf(Object),
  show: PropTypes.bool,
  onClose: PropTypes.func,
  contentClass: PropTypes.string,
  displayImageAsCover: PropTypes.bool,
  disableModalOutsideClick: PropTypes.bool,
};

export default Modal;
