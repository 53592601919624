import classnames from 'classnames';
import styles from './Section.module.css';
const {
  Root,
  NoPadding,
  NoPaddingTop,
  NoPaddingBottom,
  HasSideBar,
  MarginTop,
} = styles;
interface Props {
  className?: string;
  noPadding?: boolean;
  noPaddingTop?: boolean;
  hasSideBar?: boolean;
  children: React.ReactNode;
  noPaddingBottom?: boolean;
  marginTop?: boolean;
}
const Section = ({
  children,
  className = '',
  noPadding = false,
  noPaddingTop = false,
  noPaddingBottom,
  marginTop = false,
  hasSideBar = false,
  ...props
}: Props) => {
  const classes = classnames(
    Root,
    {
      [NoPadding]: noPadding,
      [NoPaddingTop]: noPaddingTop,
      [NoPaddingBottom]: noPaddingBottom,
      [HasSideBar]: hasSideBar,
      [MarginTop]: marginTop,
    },
    className
  );

  return (
    <section className={classes} {...props}>
      {children}
    </section>
  );
};

export default Section;
