import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from '@components/Modal';
import Heading from '@components/Heading';
import Input from '@components/Input';
import { useForm, Controller } from 'react-hook-form';
import Checkbox from '@components/Checkbox';
import Button from '@components/Button';
import WPImage from '@features/WordPress/components/WPImage';
import HTMLText from '@features/PageBuilder/components/HTMLText';
import { useSelector } from 'react-redux';
import { useSubscribeNewsletterMutation } from '@shared/services/common';
import { mapLocaleCodeToZipCodeLength } from '@features/WordPress/utilities/helpers/locale';
import { useRouter } from 'next/router';
import { captureException } from '@sentry/nextjs';
import useWordpressTranslation from '@features/WordPress/hooks/use-wordpress-translation';
import {
  Root,
  Header,
  Icon,
  NameAndZip,
  EmailInput,
  NameInput,
  ZipInput,
  Checkbox as CheckboxClass,
  // MutedText,
  SubmitButton,
} from './WashUpdateModal.module.css';

const WashUpdateModal = ({ show, onClose, className }) => {
  // const { t } = useTranslation(['checkout', 'account', 'common']);
  const translatedString = useWordpressTranslation();
  const { locale } = useRouter();
  const classes = classNames(Root, className);
  const [formSubmittedSuccessfully, setFormSubmittedSuccessfully] =
    useState(false);
  const [subscribeNewsletter] = useSubscribeNewsletterMutation();

  const { control, handleSubmit } = useForm({
    mode: 'onBlur',
  });

  const {
    options: {
      consentText,
      newsletterModal: {
        header,
        icon,
        image,
        // checkboxText,
        successMessage,
      } = {},
    } = {},
  } = useSelector((state) => state.wordpress);

  const onSubmit = useCallback(async (data) => {
    subscribeNewsletter(data)
      .unwrap()
      .then(() => setFormSubmittedSuccessfully(true))
      .catch((e) => captureException(e));
    // subscribeNewsletter(data)
    //   .unwrap()
    //   .then(() => setFormSubmittedSuccessfully(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      className={classes}
      show={show}
      onClose={onClose}
      image={image || { sourceUrl: '/assets/images/newsletter-image.png' }}
      displayImageAsCover
    >
      {icon && (
        <div className={Icon}>
          <WPImage {...icon} />
        </div>
      )}
      {formSubmittedSuccessfully ? (
        <HTMLText>{successMessage}</HTMLText>
      ) : (
        <>
          <Heading className={Header} size="small">
            {header || 'Newsletter'}
          </Heading>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={NameAndZip}>
              <div className={NameInput}>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  rules={{
                    minLength: 2,
                  }}
                  render={({ field, fieldState }) => (
                    <Input
                      type="text"
                      placeholder={translatedString('nameInputPlaceholder')}
                      label={translatedString('nameInputLabel')}
                      {...field}
                      error={fieldState.error}
                      showValidationErrorMessage={false}
                    />
                  )}
                />
              </div>
              <div className={ZipInput}>
                <Controller
                  name="zipcode"
                  control={control}
                  defaultValue=""
                  rules={{
                    minLength: {
                      value: mapLocaleCodeToZipCodeLength(locale).zipcodeLength,
                      message: translatedString('zipInputMinLength').replace(
                        '{inputLength}',
                        mapLocaleCodeToZipCodeLength(locale).zipcodeLength
                      ),
                    },
                    maxLength: {
                      value: mapLocaleCodeToZipCodeLength(locale).zipcodeLength,
                      message: translatedString('zipInputMaxLength').replace(
                        '{inputLength}',
                        mapLocaleCodeToZipCodeLength(locale).zipcodeLength
                      ),
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <Input
                      type="zipcode"
                      placeholder={translatedString('zipInputPlaceholder')}
                      label={translatedString('zipInputLabel')}
                      {...field}
                      error={fieldState.error}
                      showValidationErrorMessage={false}
                    />
                  )}
                />
              </div>
            </div>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: translatedString('validationInvalidEmail'),
                },
              }}
              render={({ field, fieldState }) => (
                <Input
                  type="text"
                  placeholder={translatedString('emailInputPlaceholder')}
                  label={translatedString('emailInputLabel')}
                  {...field}
                  error={fieldState.error}
                  className={EmailInput}
                />
              )}
            />
            <Controller
              name="consent"
              control={control}
              defaultValue={false}
              rules={{
                required: {
                  value: true,
                  message: translatedString('newsLetterRequired'),
                },
              }}
              render={({ field, fieldState }) => (
                <Checkbox
                  {...field}
                  className={CheckboxClass}
                  error={fieldState.error}
                >
                  <HTMLText>{consentText}</HTMLText>
                </Checkbox>
              )}
            />
            <Button
              className={SubmitButton}
              type="submit"
              appearance="primary"
              size="medium"
              fullWidth
            >
              {translatedString('yesPlease')}
            </Button>
          </form>
        </>
      )}
    </Modal>
  );
};

WashUpdateModal.defaultProps = {
  image: {},
  show: false,
  onClose: () => {},
};

WashUpdateModal.propTypes = {
  image: PropTypes.instanceOf(Object),
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default WashUpdateModal;
