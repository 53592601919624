import { useContext } from 'react';
import i18nStringsContext from '@contexts/i18nStringsContext';

const useWordpressTranslation = () => {
  const strings = useContext(i18nStringsContext);

  const replaceLinks = (string) =>
    string?.replace('[klik her]', '<a href="#">klik her</a>');

  return (string) => replaceLinks(strings?.[string] || string);
};

export default useWordpressTranslation;
