import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Root,
  FontSizeSuperSmall,
  FontSizeExtraSmall,
  FontSizeSmall,
  FontSizeMedium,
  FontSizeLarge,
  FontSizeExtraLarge,
  UpperCase,
  BrandColor,
} from './Heading.module.css';

const SIZES = {
  'super-small': FontSizeSuperSmall,
  'extra-small': FontSizeExtraSmall,
  small: FontSizeSmall,
  medium: FontSizeMedium,
  large: FontSizeLarge,
  'extra-large': FontSizeExtraLarge,
};

const Heading = ({
  element: Element,
  children,
  size,
  upperCase,
  hasBrandColor,
  className,
  text,
  hasHTML,
  ...props
}) => {
  let useSize = size;

  const sizes = Object.keys(SIZES).reverse();

  if (
    size === 'default' &&
    typeof Element === 'string' &&
    ['h1', 'h2', 'h3', 'h4', 'h5'].includes(Element)
  ) {
    const elementNo = Element.replace('h', '') - 1;
    useSize = sizes[elementNo];
  } else if (
    size !== 'default' &&
    ['h1', 'h2', 'h3', 'h4', 'h5'].includes(size)
  ) {
    const sizeNo = size.replace('h', '') - 1;
    useSize = sizes[sizeNo];
  }

  const classses = classnames(
    Root,
    {
      [SIZES[useSize]]: SIZES[useSize],
      [UpperCase]: upperCase,
      [BrandColor]: hasBrandColor,
    },
    className
  );
  return hasHTML ? (
    <Element
      className={classses}
      dangerouslySetInnerHTML={{
        __html: children || text,
      }}
      {...props}
    />
  ) : (
    <Element className={classses} {...props}>
      {children || text}
    </Element>
  );
};

Heading.defaultProps = {
  className: '',
  element: 'div',
  upperCase: false,
  hasBrandColor: false,
  size: 'default',
  text: '',
  hasHTML: true,
};

Heading.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  element: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  upperCase: PropTypes.bool,
  hasBrandColor: PropTypes.bool,
  hasHTML: PropTypes.bool,
  size: PropTypes.oneOf([
    'default',
    'auto',
    'super-small',
    'extra-small',
    'small',
    'medium',
    'large',
    'extra-large',

    // below list is automagically converted to string from (e.g. h1 = extra-large)
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
  ]),
  text: PropTypes.string,
};

export default Heading;
