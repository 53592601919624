import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import { Portal } from 'react-portal';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

// import TooltipIcon from '@icons/info-circle-solid.svg';
import Heading from '@components/Heading';
import {
  Root,
  IsShowing,
  Icon,
  TooltipBox,
  Arrow,
  TooltipHeader,
  TooltipContent,
} from './Tooltip.module.css';

const Tooltip = ({ className, header, content, children, clickable }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
    placement: 'top',
  });
  const [isShowing, setIsShowing] = useState(false);
  const mouseEnterHandler = () => setIsShowing(true);
  const debouncedMouseEnter = debounce(mouseEnterHandler, 200);
  const classes = classNames(
    Root,
    {
      [IsShowing]: isShowing,
    },
    className
  );

  return (
    <>
      <button
        type="button"
        onClick={() => {
          if (clickable) {
            setIsShowing(!isShowing);
          }
        }}
        onMouseEnter={!clickable ? () => debouncedMouseEnter() : null}
        onMouseLeave={
          !clickable
            ? () => {
                debouncedMouseEnter.cancel();
                setIsShowing(false);
              }
            : null
        }
        className={classes}
        ref={setReferenceElement}
        tabIndex="-1"
      >
        <img
          className={Icon}
          src="/assets/icons/info-circle-solid.svg"
          alt="Tooltip icon"
        />
        {/* <TooltipIcon className={Icon} /> */}
      </button>
      {isShowing && (
        <Portal>
          <div
            className={TooltipBox}
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            {header && <Heading className={TooltipHeader}>{header}</Heading>}
            <div className={TooltipContent}>{content || children}</div>
            <div ref={setArrowElement} className={Arrow} style={styles.arrow} />
          </div>
        </Portal>
      )}
    </>
  );
};

Tooltip.defaultProps = {
  content: '',
  clickable: false,
};
Tooltip.propTypes = {
  content: PropTypes.string,
  clickable: PropTypes.bool,
};

export default Tooltip;
